import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Tablero',
    url: '/dashboard',
    icon: 'icon-grid'
  },
  {
    title: true,
    name: 'Reservas'
  },
  {
    name: 'Nueva',
    url: '/booking/new',
    icon: 'icon-user-follow',
    attributes: { role: "booking" }
  },
  {
    name: 'Buscar',
    url: '/booking/search',
    icon: 'icon-magnifier',
    attributes: { role: "booking-list" }
  },
  {
    name: 'Listado',
    url: '/booking/guests',
    icon: 'icon-book-open',
    attributes: { role: "booking-list" }
  },
  {
    name: 'Habitaciones',
    url: '/booking/rooms',
    icon: 'icon-user-following',
    attributes: { role: "booking-list" }
  },
  {
    name: 'Calendario',
    url: '/booking/calendar',
    icon: 'icon-calendar',
    attributes: { role: "booking-list" }
  },
  /* {
    name: 'Calendario',
    url: '/booking/list',
    icon: 'icon-calendar',
    attributes: { role: "booking-list" }
  }, */
  {
    title: true,
    name: 'Objetos perdidos',
    attributes: { role: "lostobjects" }
  },
  {
    name: 'Envios pendientes',
    url: '/lostobjects/pendingshipments',
    icon: 'icon-envelope-open',
    attributes: { role: "lostobjects" }
  },
  {
    title: true,
    name: 'Facturas'
  },
  {
    name: 'Listado',
    url: '/billing/list',
    icon: 'icon-printer',
    attributes: { role: "billing" }
  },
  {
    name: 'Huespedes',
    url: '/billing/guests',
    icon: 'icon-people',
    attributes: { role: "booking-list" }
  },
  {
    name: 'Descuentos',
    url: '/billing/rates',
    icon: 'icon-calculator',
    attributes: { role: "billing" }
  },
  {
    name: 'Mensajes',
    url: '/billing/messages',
    icon: 'icon-envelope-letter',
    attributes: { role: "billing" }
  },
  {
    title: true,
    name: 'Limpieza'
  },
  {
    name: 'Gestión de Hab.',
    url: '/cleaning/rooms',
    icon: 'icon-calendar',
    attributes: { role: "cleaning" }
  },
  {
    name: 'Gestión Revenue',
    url: '/cleaning/revenue',
    icon: 'icon-calendar',
    attributes: { role: "cleaning" }
  },
  {
    name: 'Tareas diarias',
    url: '/cleaning/daily',
    icon: 'icon-info',
    attributes: { role: "cleaning" }
  },
  {
    name: 'Mantenimiento',
    url: '/cleaning/maintenance',
    icon: 'icon-wrench',
    attributes: { role: "cleaning" }
  },
  {
    name: 'Cuestionario',
    url: '/cleaning/questions',
    icon: 'icon-question',
    attributes: { role: "cleaning" }
  },
  {
    name: 'Almohada / Colchón',
    url: '/cleaning/pillows',
    icon: 'icon-crop',
    attributes: { role: "cleaning" }
  },
  {
    title: true,
    name: 'Facturación'
  },
  {
    name: 'Resumen Clientes',
    url: '/reports/billing',
    icon: 'icon-pie-chart',
    attributes: { role: "billing" }
  },
  {
    name: 'Facturas Generadas',
    url: '/reports/bdetails',
    icon: 'icon-pie-chart',
    attributes: { role: "billing" }
  },
  {
    name: 'Facturas Abono',
    url: '/reports/bdroppeds',
    icon: 'icon-pie-chart',
    attributes: { role: "billing" }
  },
  {
    title: true,
    name: 'Informes'
  },
  {
    name: 'Pagos',
    url: '/reports/payments',
    icon: 'icon-calculator',
    attributes: { role: "billing" }
  },
  {
    name: 'Extras',
    url: '/reports/extras',
    icon: 'icon-drawer',
    attributes: { role: "billing" }
  },
  {
    name: 'Extras (calendario)',
    url: '/reports/ecalendar',
    icon: 'icon-drawer',
    attributes: { role: "billing" }
  },
  {
    name: 'Consumibles',
    url: '/reports/consumables',
    icon: 'icon-wallet',
    attributes: { role: "billing" }
  },
  {
    name: 'Resrv. Cacenladas',
    url: '/reports/droppeds',
    icon: 'icon-minus',
    attributes: { role: "billing" }
  },
  {
    name: 'Consumos Detallados',
    url: '/reports/general',
    icon: 'icon-list',
    attributes: { role: "billing" }
  },
  {
    title: true,
    name: 'General'
  },
  {
    name: 'Habitaciones',
    url: '/settings/rooms',
    icon: 'icon-home',
    attributes: { role: "rooms" }
  },
  {
    name: 'Categorías / Servicios',
    url: '/settings/categories',
    icon: 'icon-puzzle',
    attributes: { role: "catandsrv" }
  },
  {
    name: 'Extras',
    url: '/settings/extras',
    icon: 'icon-layers',
    attributes: { role: "extras" }
  },
  {
    name: 'Extras Synergy',
    url: '/settings/esyn',
    icon: 'icon-layers',
    attributes: { role: "extras" }
  },
  {
    name: 'Restaurantes',
    url: '/restaurants',
    icon: 'icon-layers',
    attributes: { role: "restaurants" }
  },
  {
    name: 'Consumibles',
    url: '/settings/consumables',
    icon: 'icon-layers',
    attributes: { role: "consumables" }
  },
  {
    name: 'Comidas',
    url: '/settings/foods',
    icon: 'icon-layers',
    attributes: { role: "consumables" }
  },
  {
    name: 'Tutoriales',
    url: '/settings/tutorials',
    icon: 'icon-control-play',
    attributes: { role: "tutorials" }
  },
  {
    name: 'Promociones',
    url: '/settings/promotions',
    icon: 'icon-fire',
    attributes: { role: "promotions" }
  },
  {
    name: 'Cod. Promo',
    url: '/settings/promos',
    icon: 'icon-tag',
    attributes: { role: "promocodes" }
  },
  {
    title: true,
    name: 'Ajustes'
  },
  {
    name: 'Limites',
    url: '/settings/limits',
    icon: 'icon-speedometer',
    attributes: { role: "hotels" }
  },
  {
    name: 'Usuarios',
    url: '/settings/users',
    icon: 'icon-people',
    attributes: { role: "users" }
  },
  {
    name: 'Cerraduras',
    url: '/settings/doors',
    icon: 'icon-lock-open',
    attributes: { role: "hotels" }
  },
  {
    name: 'Synergy',
    url: '/settings/synergy',
    icon: 'icon-lock-open',
    attributes: { role: "booking" }
  },
  {
    name: 'Hoteles',
    url: '/settings/hotels',
    icon: 'icon-puzzle',
    attributes: { role: "hotels" }
  },
  {
    name: 'Ajus. Avanzados',
    url: '/settings/advanced',
    icon: 'icon-settings',
    attributes: { role: "advanced" }
  },
  /* {
    name: 'Mi Llave',
    url: '/settings/millave',
    icon: 'icon-key',
    attributes: { role: "millave" }
  }, */
  /* {
    name: 'Ajustes',
    url: '/#!',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Habitaciones',
        url: '/settings/rooms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Categorías / Servicios',
        url: '/settings/categories',
        icon: 'icon-puzzle'
      },
      {
        name: 'Extras',
        url: '/settings/extras',
        icon: 'icon-puzzle'
      }
    ]
  }, */
  /* {
    name: 'Reportes',
    url: '/#!',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      },
      {
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      }
    ]
  }, */


  /* {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Theme'
  },
  {
    name: 'Colors',
    url: '/theme/colors',
    icon: 'icon-drop'
  },
  {
    name: 'Typography',
    url: '/theme/typography',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Components'
  },
  {
    name: 'Base',
    url: '/base',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      },
      {
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/base/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Forms',
        url: '/base/forms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Navbars',
        url: '/base/navbars',
        icon: 'icon-puzzle'

      },
      {
        name: 'Pagination',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress',
        url: '/base/progress',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tables',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Buttons',
    url: '/buttons',
    icon: 'icon-cursor',
    children: [
      {
        name: 'Buttons',
        url: '/buttons/buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Dropdowns',
        url: '/buttons/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Brand Buttons',
        url: '/buttons/brand-buttons',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Charts',
    url: '/charts',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'CoreUI Icons',
        url: '/icons/coreui-icons',
        icon: 'icon-star',
        badge: {
          variant: 'success',
          text: 'NEW'
        }
      },
      {
        name: 'Flags',
        url: '/icons/flags',
        icon: 'icon-star'
      },
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    children: [
      {
        name: 'Alerts',
        url: '/notifications/alerts',
        icon: 'icon-bell'
      },
      {
        name: 'Badges',
        url: '/notifications/badges',
        icon: 'icon-bell'
      },
      {
        name: 'Modals',
        url: '/notifications/modals',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Extras',
  },
  {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-star',
    children: [
      {
        name: 'Login',
        url: '/login',
        icon: 'icon-star'
      },
      {
        name: 'Register',
        url: '/register',
        icon: 'icon-star'
      },
      {
        name: 'Error 404',
        url: '/404',
        icon: 'icon-star'
      },
      {
        name: 'Error 500',
        url: '/500',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Disabled',
    url: '/dashboard',
    icon: 'icon-ban',
    badge: {
      variant: 'secondary',
      text: 'NEW'
    },
    attributes: { disabled: true },
  },
  {
    name: 'Download CoreUI',
    url: 'http://coreui.io/angular/',
    icon: 'icon-cloud-download',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener' }
  },
  {
    name: 'Try CoreUI PRO',
    url: 'http://coreui.io/pro/angular/',
    icon: 'icon-layers',
    variant: 'danger',
    attributes: { target: '_blank', rel: 'noopener' }
  } */
];
