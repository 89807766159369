import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { iEvent, iToken, iCategory, iService, iRoom, emptyCategory, emptyService, emptyRoom, iExtra, emptyExtra, iLostObjectShipments, iRoomNumber, iRoomNumberLock, emptyRoomNumber, iGuest, emptyGuest, iBooking, emptyBooking, emptyRole, iRole, iUser, emptyUser, emptyHotel, iHotel, emptyExtraMat, iExtraMat, emptyBookingExtraMat, iBookingExtraMat, emptyBilling, iBilling, iPromoCode, emptyCode, iRate, emptyRate, iGuestDocument, emptyGuestDocument, iCountry, iCompany, emptyCompany, iPayment, emptyPayment, iZone, emptyZone, iMattress, emptyMattress, iPillow, emptyPillow, iConsumable, emptyConsumable, iCard, emptyCard, iTask, emptyTask, iQuestion, emptyQuestion, iQuestionsBooking, iLimits, emptyLimits, iFood, emptyFood, iResponse, emptyCivitatisDestinations, emptyPromotions, emptyTutorials, iCivitatis, iPromotions, iRestaurant, iRestaurantHotels, iRestaurantOpeningHours, iTutorials, iMillaveDoor, iCalendar, iCalendarFilter, iRoomNumberClean, iMaintenance, emptyMaintenance, iBookingSynergy, iBookingLostObject, emptyBookingLostObject, iExtraSynergy, emptyExtraSynergy, emptyRestaurant, emptyRestaurantOpeningHours, iRestaurantFamily, emptyRestaurantFamily, iRestaurantProduct, emptyRestaurantProduct, iRestaurantProductVariant, emptyRestaurantProductVariant, iRestaurantProfile } from '../app.interfaces';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiEvents = new Subject<iEvent>();
  showLoader: boolean = true;
  autohideLoader: boolean = true;

  private API_SERVER: string = `${environment.apiUrl}/admin/`;
  private API_SERVER_PUBLIC: string = `${environment.apiUrl}/`;

  constructor(
    private http: HttpClient
  ) { }

  /* AUTH */

  async login(email: string, password: string) {
    try {
      return await this.http.post<iToken>(`${this.API_SERVER}auth/login`, {
        email: email, password: password
      }).toPromise();
    } catch (e) {
      return { token: undefined };
    }
  }

  async requestToken() {
    try {
      return await this.http.get<iToken>(`${this.API_SERVER}auth/token`).toPromise();
    } catch (e) {
      return { token: undefined };
    }
  }

  async requestAdvancedToken(userAgent: string) {
    try {
      return await this.http.post<iToken>(`${this.API_SERVER}auth/advanced/token`, {userAgent: userAgent}).toPromise();
    } catch (e) {
      return { token: undefined };
    }
  }

  /* SETTINGS */

  async getCategories() {
    return await this.http.get<iCategory[]>(`${this.API_SERVER}categories`).toPromise();
  }

  async createCategory(category: iCategory) {
    try {
      return await this.http.post<iCategory>(`${this.API_SERVER}categories`, category).toPromise();
    } catch (e) {
      return emptyCategory;
    }
  }

  async updateCategory(category: iCategory) {
    try {
      return await this.http.put<iCategory>(`${this.API_SERVER}categories`, category).toPromise();
    } catch (e) {
      return emptyCategory;
    }
  }

  async deleteCategory(id: number) {
    try {
      return await this.http.delete<iCategory>(`${this.API_SERVER}categories`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyCategory;
    }
  }

  async getServices() {
    return await this.http.get<iService[]>(`${this.API_SERVER}services`).toPromise();
  }

  async createService(service: iService) {
    try {
      return await this.http.post<iService>(`${this.API_SERVER}services`, service).toPromise();
    } catch (e) {
      return emptyService;
    }
  }

  async updateService(service: iService) {
    try {
      return await this.http.put<iService>(`${this.API_SERVER}services`, service).toPromise();
    } catch (e) {
      return emptyService;
    }
  }

  async deleteService(id: number) {
    try {
      return await this.http.delete<iService>(`${this.API_SERVER}services`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyService;
    }
  }

  async getRooms(revenue?: string) {
    return await this.http.get<iRoom[]>(`${this.API_SERVER}rooms`, { params: { revenue } }).toPromise();
  }

  async createRoom(room: iRoom) {
    try {
      return await this.http.post<iRoom>(`${this.API_SERVER}rooms`, room).toPromise();
    } catch (e) {
      return emptyRoom;
    }
  }

  async updateRoom(room: iRoom) {
    try {
      return await this.http.put<iRoom>(`${this.API_SERVER}rooms`, room).toPromise();
    } catch (e) {
      return emptyRoom;
    }
  }

  async deleteRoom(id: number) {
    try {
      return await this.http.delete<iRoom>(`${this.API_SERVER}rooms`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyRoom;
    }
  }

  async getExtraTimer(bookingId: string, extraId: string) {
    return await this.http.get<iExtra[]>(`${this.API_SERVER_PUBLIC}extras/timer`, {
      params: { bookingId, extraId }
    }).toPromise();
  }

  async addExtraTimer(bookingId: number, extraId: number, minutes: number) {
    return await this.http.post<any>(`${this.API_SERVER_PUBLIC}extras/timer`, {
      bookingId, extraId, minutes
    }).toPromise();
  }

  async getExtras(filter?: string) {
    return await this.http.get<iExtra[]>(`${this.API_SERVER}extras`, {
      params: { filter }
    }).toPromise();
  }

  async getExtrasCalendar(type?: string) {
    return await this.http.get<any>(`${this.API_SERVER}extras/calendar`, {
      params: { type }
    }).toPromise();
  }

  async createExtra(extra: iExtra) {
    try {
      return await this.http.post<iExtra>(`${this.API_SERVER}extras`, extra).toPromise();
    } catch (e) {
      return emptyExtra;
    }
  }

  async updateExtra(extra: iExtra) {
    try {
      return await this.http.put<iExtra>(`${this.API_SERVER}extras`, extra).toPromise();
    } catch (e) {
      return emptyExtra;
    }
  }

  async deleteExtra(id: number) {
    try {
      return await this.http.delete<iExtra>(`${this.API_SERVER}extras`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyExtra;
    }
  }

  async getExtrasMat() {
    return await this.http.get<iExtraMat[]>(`${this.API_SERVER}extras/mat`).toPromise();
  }

  async createExtraMat(extra: iExtraMat) {
    try {
      return await this.http.post<iExtraMat>(`${this.API_SERVER}extras/mat`, extra).toPromise();
    } catch (e) {
      return emptyExtraMat;
    }
  }

  async updateExtraMat(extra: iExtraMat) {
    try {
      return await this.http.put<iExtraMat>(`${this.API_SERVER}extras/mat`, extra).toPromise();
    } catch (e) {
      return emptyExtraMat;
    }
  }

  async deleteExtraMat(id: number) {
    try {
      return await this.http.delete<iExtraMat>(`${this.API_SERVER}extras/mat`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyExtraMat;
    }
  }

  async getExtrasSyn() {
    return await this.http.get<iExtraSynergy[]>(`${this.API_SERVER}extras/synergy`).toPromise();
  }

  async createExtraSyn(extra: iExtraSynergy) {
    try {
      return await this.http.post<iExtraSynergy>(`${this.API_SERVER}extras/synergy`, extra).toPromise();
    } catch (e) {
      return emptyExtraSynergy;
    }
  }

  async updateExtraSyn(extra: iExtraSynergy) {
    try {
      return await this.http.put<iExtraSynergy>(`${this.API_SERVER}extras/synergy`, extra).toPromise();
    } catch (e) {
      return emptyExtraSynergy;
    }
  }

  async deleteExtraSyn(id: number) {
    try {
      return await this.http.delete<iExtraSynergy>(`${this.API_SERVER}extras/synergy`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyExtraSynergy;
    }
  }

  async getConsumables() {
    return await this.http.get<iConsumable[]>(`${this.API_SERVER}consumables`).toPromise();
  }

  async createConsumable(consumable: iConsumable) {
    try {
      return await this.http.post<iConsumable>(`${this.API_SERVER}consumables`, consumable).toPromise();
    } catch (e) {
      return emptyConsumable;
    }
  }

  async updateConsumable(consumable: iConsumable) {
    try {
      return await this.http.put<iConsumable>(`${this.API_SERVER}consumables`, consumable).toPromise();
    } catch (e) {
      return emptyConsumable;
    }
  }

  async deleteConsumable(id: number) {
    try {
      return await this.http.delete<iConsumable>(`${this.API_SERVER}consumables`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyConsumable;
    }
  }

  async getFoods(filter?: string) {
    return await this.http.get<iFood[]>(`${this.API_SERVER}foods`, {
      params: { filter }
    }).toPromise();
  }

  async createFood(food: iFood) {
    try {
      return await this.http.post<iFood>(`${this.API_SERVER}foods`, food).toPromise();
    } catch (e) {
      return emptyFood;
    }
  }

  async updateFood(food: iFood) {
    try {
      return await this.http.put<iFood>(`${this.API_SERVER}foods`, food).toPromise();
    } catch (e) {
      return emptyFood;
    }
  }

  async deleteFood(id: number) {
    try {
      return await this.http.delete<iFood>(`${this.API_SERVER}foods`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyFood;
    }
  }

  async registerBookingLostObject(lostObject: iBookingLostObject, hotel: string) {
    try {
      return await this.http.post<iBookingLostObject>(`${this.API_SERVER}booking/lostobjects?hotel=${hotel}`, lostObject).toPromise();
    } catch (e) {
      return emptyBookingLostObject;
    }
  }

  async updateBookingLostObject(lostObject: iBookingLostObject) {
    try {
      return await this.http.put<iBookingLostObject>(`${this.API_SERVER}booking/lostobjects`, lostObject).toPromise();
    } catch (e) {
      return emptyBookingLostObject;
    }
  }

  async addBookingConsumable(bookedroom: iBooking, bookingExtra: iConsumable) {
    return await this.http.post<iConsumable>(`${this.API_SERVER}booking/consumables`, {
      id: bookingExtra.id,
      reservation: bookedroom.id,
      quantity: bookingExtra.quantity
    }).toPromise();
  }

  async deleteBookingConsumable(bookedroom: iBooking, bookingExtra: iConsumable) {
    try {
      return await this.http.delete<iConsumable>(`${this.API_SERVER}booking/consumables`, {
        params: { id: `${bookingExtra.id}`, reservation: bookedroom.id }
      }).toPromise();
    } catch (e) {
      return emptyConsumable;
    }
  }

  async getReserve(id: string) {
    return await this.http.get<iBooking>(`${this.API_SERVER}booking`, { params: { id } }).toPromise();
  }

  async getReserves(from: number, to: number, hidden?: boolean) {
    let params: any = { reserve_from: `${from}`, reserve_to: `${to}` }
    if (typeof hidden !== 'undefined') { params.filterHidden = !!hidden ? 1 : 0 }
    return await this.http.get<iBooking[]>(`${this.API_SERVER}rooms/reservations`, { params }).toPromise();
  }

  async getSearchedReserves(value: string) {
    return await this.http.get<iBooking[]>(`${this.API_SERVER}rooms/reservations/search`, {
      params: { value }
    }).toPromise();
  }

  async getBookingReserves(number: string)
  {
    try
    {
      let params: any = { booking_number: `${number}`, booking_type: `booking` }
      return await this.http.get<iBooking[]>(`${this.API_SERVER}rooms/reservations`, { params }).toPromise();
    } catch {
      return [emptyBooking];
    }
  }

  async getAllReserves() {
    return await this.http.get<iBooking[]>(`${this.API_SERVER}rooms/reservations`).toPromise();
  }

  async getReservesFromToday(params: any) {
    return await this.http.get<iBooking[]>(`${this.API_SERVER}rooms/reservations`, { params }).toPromise();
  }

  async getPendingReserves() {
    return await this.http.get<iBooking[]>(`${this.API_SERVER}rooms/reservations/pending`).toPromise();
  }

  async getRoomNumbers(roomId: number, all: boolean) {
    return await this.http.get<iRoomNumber[]>(`${this.API_SERVER}rooms/numbers`, {
      params: { room: `${roomId}`, all: `${!!all ? 1 : 0}` }
    }).toPromise();
  }

  async getRoomNumbersWithClean(roomId: number, clean: string) {
    return await this.http.get<iRoomNumber[]>(`${this.API_SERVER}rooms/numbers`, {
      params: { room: `${roomId}`, clean, all: '1' }
    }).toPromise();
  }

  async getAvailableRoomNumbers(roomId: number) {
    return await this.http.get<iRoomNumber[]>(`${this.API_SERVER}rooms/numbers/availables`, {
      params: { room: `${roomId}` }
    }).toPromise();
  }

  async getLostObjectsShiptPending() {
    return await this.http.get<iLostObjectShipments[]>(`${this.API_SERVER}lostobjects/shipments/pending`).toPromise();
  }

  async setLostObjectsShiptPickedup(shipments: string)
  {
    return await this.http.put<iLostObjectShipments[]>(`${this.API_SERVER}lostobjects/shipments/pickedup`, { shipments: shipments }).toPromise();
  }

  async createRoomNumber(room: iRoomNumber) {
    try {
      return await this.http.post<iRoom>(`${this.API_SERVER}rooms/numbers`, room).toPromise();
    } catch (e) {
      return emptyRoomNumber;
    }
  }

  async updateRoomNumber(room: iRoomNumber) {
    try {
      return await this.http.put<iRoom>(`${this.API_SERVER}rooms/numbers`, room).toPromise();
    } catch (e) {
      return emptyRoomNumber;
    }
  }

  async deleteRoomNumber(id: number) {
    try {
      return await this.http.delete<iRoom>(`${this.API_SERVER}rooms/numbers`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyRoomNumber;
    }
  }

  async getLockedRoomNumbers(type: string) {
    return await this.http.get<iRoomNumberLock[]>(`${this.API_SERVER}rooms/numbers/lock`, { params: { type } }).toPromise();
  }

  async setLockedRoomNumbers(number: string, type: string) {
    return await this.http.post<iRoomNumberLock>(`${this.API_SERVER}rooms/numbers/lock`, { number, type }).toPromise();
  }

  async removeLockedRoomNumbers(number: string, type: string) {
    return await this.http.delete<iRoomNumberLock>(`${this.API_SERVER}rooms/numbers/lock`, { params: { number: encodeURIComponent(number), type } }).toPromise();
  }

  async checkIn(reservation: iBooking, number: iRoomNumber) {
    try {
      return await this.http.post<iBooking>(`${this.API_SERVER}rooms/checkin`, {
        id: reservation.id, number: number.id
      }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async checkOut(reservation: iBooking) {
    try {
      return await this.http.post<iBooking>(`${this.API_SERVER}rooms/checkout`, {
        id: reservation.id
      }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async findGuest(filter: string, text: string) {
    try {
      return await this.http.get<iGuest>(`${this.API_SERVER}guests/search`, {
        params: { by: filter, value: text, list: 'n' }
      }).toPromise();
    } catch (e) {
      return emptyGuest;
    }
  }

  async findGuests(filter: string, text: string) {
    return await this.http.get<iGuest[]>(`${this.API_SERVER}guests/search`, {
      params: { by: filter, value: text, list: 'y' }
    }).toPromise();
  }

  async createGuest(guest: iGuest) {
    try {
      return await this.http.post<iGuest>(`${this.API_SERVER}guests`, guest).toPromise();
    } catch (e) {
      return emptyGuest;
    }
  }

  async updateGuest(guestData: iGuest) {
    try {
      return await this.http.put<iGuest>(`${this.API_SERVER}guests`, guestData).toPromise();
    } catch (e) {
      return emptyGuest;
    }
  }

  async uploadGuestDocument(document: iGuestDocument) {
    try {
      return await this.http.post<iGuestDocument>(`${this.API_SERVER}guests/document`, document).toPromise();
    } catch (e) {
      return emptyGuestDocument;
    }
  }

  async dropGuestDocument(document: iGuestDocument) {
    try {
      return await this.http.delete<iGuestDocument>(`${this.API_SERVER}guests/document`, {
        params: { id: `${document.id}` }
      }).toPromise();
    } catch (e) {
      return emptyGuestDocument;
    }
  }

  async addBooking(booking: iBooking) {
    try {
      return await this.http.post<iBooking>(`${this.API_SERVER}booking`, booking).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async updateBooking(booking: iBooking) {
    try {
      return await this.http.put<iBooking>(`${this.API_SERVER}booking`, booking).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async updateBookingHour(booking: iBooking) {
    try {
      return await this.http.put<iBooking>(`${this.API_SERVER}booking/hour`, booking).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async updateBookingEmail(booking: iBooking, type: string, value: boolean) {
    return await this.http.patch<iResponse>(`${this.API_SERVER}booking/email`, { id: booking.id, type, value }).toPromise();
  }

  async dropBooking(booking: iBooking) {
    try {
      return await this.http.delete<iBooking>(`${this.API_SERVER}booking`, {
        params: { id: booking.id }
      }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async hideBooking(booking: iBooking) {
    try {
      return await this.http.put<iBooking>(`${this.API_SERVER}booking/noshow`, { id: booking.id }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async refundBooking(booking: iBooking) {
    try {
      return await this.http.put<iBooking>(`${this.API_SERVER}booking/refund`, { id: booking.id }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async updateBookingGuest(id: number, guest: number) {
    return await this.http.post<iResponse>(`${this.API_SERVER}booking/guest`, { id, guest }).toPromise();
  }

  async addGuestBooking(booking: iBooking, guests: string) {
    try {
      return await this.http.post<iBooking>(`${this.API_SERVER}booking/guests`, {
        id: booking.id,
        guests: guests
      }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async updateGuestBooking(booking: iBooking, guests: string) {
    try {
      return await this.http.put<iBooking>(`${this.API_SERVER}booking/guests`, {
        id: booking.id,
        guests: guests
      }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async dropGuestBooking(booking: iBooking, guests: string) {
    try {
      return await this.http.delete<iBooking>(`${this.API_SERVER}booking/guests`, {
        params: { id: booking.id, guests: guests }
      }).toPromise();
    } catch (e) {
      return emptyBooking;
    }
  }

  async moveBookingRoom(reservId, oldRoom, newRoom, revenue?: string) {
    return await this.http.post<any>(`${this.API_SERVER}booking/move`, {
      reservationId: reservId, newRoomNumber: newRoom, oldRoomNumber: oldRoom, revenue
    }).toPromise();
  }

  /* async payBookingRoom (reservId) {
    return await this.http.post<any>(`${this.API_SERVER}booking/pay`, {
      reservationId: reservId
    }).toPromise();
  } */

  async getBookingExtras(booking: iBooking) {
    try {
      return await this.http.get<iBookingExtraMat[]>(`${this.API_SERVER}booking/extras`, {
        params: { id: booking.id }
      }).toPromise();
    } catch (e) {
      return [emptyBookingExtraMat];
    }
  }

  async checkBookingExtraMat(bookingExtra: iBookingExtraMat) {
    return await this.http.post<any>(`${this.API_SERVER}booking/extras/check`, bookingExtra).toPromise();
  }

  async addBookingExtraMat(bookingExtra: iBookingExtraMat) {
    return await this.http.post<iBookingExtraMat>(`${this.API_SERVER}booking/extras`, bookingExtra).toPromise();
  }

  async deleteBookingExtraMat(bookingExtra: iBookingExtraMat) {
    try {
      return await this.http.delete<iBookingExtraMat>(`${this.API_SERVER}booking/extras`, {
        params: { id: `${bookingExtra.id}` }
      }).toPromise();
    } catch (e) {
      return emptyBookingExtraMat;
    }
  }

  async deleteBookingExtraSynergy(bookedroom: iBooking, bookingExtra: iExtraSynergy) {
    try {
      return await this.http.delete<iExtraSynergy>(`${this.API_SERVER}booking/extras/synergy`, {
        params: { id: `${bookingExtra.id}`, reservation: bookedroom.id }
      }).toPromise();
    } catch (e) {
      return emptyConsumable;
    }
  }

  async getRoles() {
    try {
      return await this.http.get<iRole[]>(`${this.API_SERVER}auth/users/roles/all`).toPromise();
    } catch (e) {
      return [emptyRole]
    }
  }

  async getUserRoles(userId?: number) {
    try {
      return await this.http.get<iRole[]>(`${this.API_SERVER}auth/users/roles`, userId ? {
        params: { user: `${userId}` }
      } : {}).toPromise();
    } catch (e) {
      return [emptyRole]
    }
  }

  async getUserHotels(userId?: number) {
    try {
      return await this.http.get<iHotel[]>(`${this.API_SERVER}auth/users/hotels`, userId ? {
        params: { user: `${userId}` }
      } : {}).toPromise();
    } catch (e) {
      return [emptyHotel]
    }
  }

  async getUsers() {
    try {
      return await this.http.get<iUser[]>(`${this.API_SERVER}auth/users`).toPromise();
    } catch (e) {
      return [emptyUser];
    }
  }

  async createUser(user: iUser) {
    try {
      return await this.http.post<iUser>(`${this.API_SERVER}auth/users`, user).toPromise();
    } catch (e) {
      return emptyUser;
    }
  }

  async updateUser(user: iUser) {
    try {
      return await this.http.put<iUser>(`${this.API_SERVER}auth/users`, user).toPromise();
    } catch (e) {
      return emptyUser;
    }
  }

  async deleteUser(id: number) {
    try {
      return await this.http.delete<iUser>(`${this.API_SERVER}auth/users`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyUser;
    }
  }

  async getHotels() {
    try {
      return await this.http.get<iHotel[]>(`${this.API_SERVER}hotels`).toPromise();
    } catch (e) {
      return [emptyHotel];
    }
  }

  async getZones() {
    try {
      return await this.http.get<iZone[]>(`${this.API_SERVER}hotels/zones`).toPromise();
    } catch (e) {
      return [emptyZone];
    }
  }

  async getHotelStats() {
    return await this.http.get<any>(`${this.API_SERVER}hotels/stats`).toPromise();
  }

  async getAllHotels() {
    try {
      return await this.http.get<iHotel[]>(`${this.API_SERVER}hotels/all`).toPromise();
    } catch (e) {
      return [emptyHotel];
    }
  }

  async createHotel(hotel: iHotel) {
    try {
      return await this.http.post<iHotel>(`${this.API_SERVER}hotels`, hotel).toPromise();
    } catch (e) {
      return emptyHotel;
    }
  }

  async updateHotel(hotel: iHotel) {
    try {
      const response = await this.http.post<iHotel[]>(`${this.API_SERVER}hotels/status`, {
        id: hotel.id, enabled: hotel.enabled
      }).toPromise();
      this.apiEvents.next({ action: 'hotel', value: response });
      return response;
    } catch (e) {
      return emptyHotel;
    }
  }

  async valdiateHotelPassword(hotel: number, password: string) {
    return await this.http.post<iResponse>(`${this.API_SERVER}hotels/password/validate`, {
      hotel, password
    }).toPromise();
  }

  async getGuestReserves(guestEmail: string) {
    try {
      return await this.http.get<iBooking[]>(`${this.API_SERVER}guests/reservations`, {
        params: { value: guestEmail }
      }).toPromise();
    } catch (e) {
      return [emptyBooking];
    }
  }

  async getGuestPendingReserves(guestEmail: string) {
    try {
      return await this.http.get<iBooking[]>(`${this.API_SERVER}guests/reservations/pending`, {
        params: { value: guestEmail }
      }).toPromise();
    } catch (e) {
      return [emptyBooking];
    }
  }

  async getGuestCardReserves(card: string) {
    try {
      return await this.http.get<iBooking[]>(`${this.API_SERVER}guests/reservations/card`, {
        params: { card: card }
      }).toPromise();
    } catch (e) {
      return [];
    }
  }

  async getBillingReservation(reservation: number) {
    try {
      return await this.http.get<iBilling>(`${this.API_SERVER}billings/reservation`, {
        params: { id: `${reservation}` }
      }).toPromise();
    } catch (e) {
      return emptyBilling;
    }
  }

  async setBillingCompany(billing: iBilling, company: iCompany) {
    try {
      return await this.http.post<iBilling>(`${this.API_SERVER}billings/company`, {
        billing: billing, company: company
      }).toPromise();
    } catch (e) {
      return emptyBilling;
    }
  }


  async getBillings(values?: any) {
    try {
      return await this.http.get<iBilling[]>(`${this.API_SERVER}billings`, values ? {
        params: values
      } : {}).toPromise();
    } catch (e) {
      return [emptyBilling];
    }
  }

  async downloadBilling(billing: number) {
    const report = await this.http.post<any>(`${this.API_SERVER}billings/download`, {
      id: billing
    }).toPromise();
    return this.http.get(`${environment.apiUrl}/reports/billings/${report.id}.pdf`, {
      responseType: 'blob'
    }).pipe(map(res => new Blob([res], { type: 'application/pdf' }))).toPromise();
  }

  async markDownload(billing: iBilling, type: string, check: boolean) {
    return await this.http.post<iResponse>(`${this.API_SERVER}billings/download/check`, { ...billing, type, check }).toPromise();
  }

  async sendBillingEmail(formData: any) {
    return await this.http.post<iResponse>(`${this.API_SERVER}billings/send`, formData).toPromise();
  }

  async confirmNewBilling(formData: any) {
    return await this.http.post<iBilling>(`${this.API_SERVER}billings/confirm`, formData).toPromise();
  }

  /* async sendPrecheckinEmail (formData: any) {
    return await this.http.post<iResponse>(`${this.API_SERVER}billings/send/precheckin`, formData).toPromise();
  } */

  async getDoorList() {
    try {
      return await this.http.post<iMillaveDoor[]>(`${this.API_SERVER}millave/door/list`, {}).toPromise();
    } catch (e) {
      return [];
    }
  }

  async setDoorLockId(door: string, number: iRoomNumber, type: string) {
    try {
      return await this.http.post<iMillaveDoor>(`${this.API_SERVER}millave/door/update`, {
        doorId: door,
        numberId: number.id,
        doorType: type
      }).toPromise();
    } catch (e) {
      return iMillaveDoor;
    }
  }

  async addIdentityCard(door: string, code: string, start: string, end: string, billing?: number) {
    return await this.http.post<any>(`${this.API_SERVER}millave/identityCard/add`, {
      doorId: door,
      cardNumber: code,
      startDate: start,
      endDate: end,
      billingId: billing
    }).toPromise();
  }

  async removeIdentityCard(door: string, code: string) {
    return await this.http.post<any>(`${this.API_SERVER}millave/identityCard/delete`, {
      doorId: door,
      cardId: code
    }).toPromise();
  }

  async getPromoCode(code: string) {
    try {
      return await this.http.get<iPromoCode>(`${this.API_SERVER}promo`, {
        params: { value: code }
      }).toPromise();
    } catch (e) {
      return emptyCode;
    }
  }

  async getPromoCodeList() {
    try {
      return await this.http.get<iPromoCode[]>(`${this.API_SERVER}promo/all`, {}).toPromise();
    } catch (e) {
      return [];
    }
  }

  async createPromoCode(promo: iPromoCode) {
    try {
      return await this.http.post<iPromoCode>(`${this.API_SERVER}promo`, promo).toPromise();
    } catch (e) {
      return emptyCode;
    }
  }

  async deletePromoCode(id: number) {
    try {
      return await this.http.delete<iPromoCode>(`${this.API_SERVER}promo`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyCode;
    }
  }

  async applyBillingPromoCode(billing: iBilling, promo: iPromoCode) {
    try {
      return await this.http.post<iPromoCode>(`${this.API_SERVER}billings/promo`, {
        billing: billing.id,
        code: promo.code
      }).toPromise();
    } catch (e) {
      return emptyBilling;
    }
  }

  async removeBillingPromoCode(id: number, bill: number) {
    try {
      return await this.http.delete<any>(`${this.API_SERVER}billings/promo`, {
        params: { id: `${id}`, bill: `${bill}` }
      }).toPromise();
    } catch (e) {
      return emptyBilling;
    }
  }

  async registerBillingPayment(payment: iPayment) {
    try {
      return await this.http.post<iPayment>(`${this.API_SERVER}billings/payment`, payment).toPromise();
    } catch (e) {
      return emptyPayment;
    }
  }

  async updateBillingPayment(payment: iPayment) {
    try {
      return await this.http.put<iPayment>(`${this.API_SERVER}billings/payment`, payment).toPromise();
    } catch (e) {
      return emptyPayment;
    }
  }

  async removeBillingPayment(payment: iPayment) {
    try {
      return await this.http.delete<iPayment>(`${this.API_SERVER}billings/payment`, {
        params: { id: `${payment.id}` }
      }).toPromise();
    } catch (e) {
      return emptyPayment;
    }
  }

  async getRates(from: number, to: number) {
    return await this.http.get<iRate[]>(`${this.API_SERVER}rooms/rates`, {
      params: { date_from: `${from}`, date_to: `${to}` }
    }).toPromise();
  }

  async getAllRates() {
    return await this.http.get<iRate[]>(`${this.API_SERVER}rooms/rates`).toPromise();
  }

  async createRate(rate: iRate) {
    try {
      return await this.http.post<iRate>(`${this.API_SERVER}rooms/rates`, rate).toPromise();
    } catch (e) {
      return emptyRate;
    }
  }

  async updateRate(rate: iRate) {
    try {
      return await this.http.put<iRate>(`${this.API_SERVER}rooms/rates`, rate).toPromise();
    } catch (e) {
      return emptyRate;
    }
  }

  async deleteRate(id: number) {
    try {
      return await this.http.delete<iRate>(`${this.API_SERVER}rooms/rates`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyRate;
    }
  }

  async getCountries() {
    return await this.http.get<iCountry[]>(`${this.API_SERVER}countries`).toPromise();
  }

  async getCompanies() {
    return await this.http.get<iCompany[]>(`${this.API_SERVER}companies`).toPromise();
  }

  async findCompany(field: string, value: string) {
    return await this.http.get<iCompany>(`${this.API_SERVER}companies/search`, {
      params: { field: field, value: value }
    }).toPromise();
  }

  async createCompany(company: iCompany) {
    try {
      return await this.http.post<iCompany>(`${this.API_SERVER}companies`, company).toPromise();
    } catch (e) {
      return emptyCompany;
    }
  }

  async updateCompany(company: iCompany) {
    try {
      return await this.http.put<iCompany>(`${this.API_SERVER}companies`, company).toPromise();
    } catch (e) {
      return emptyCompany;
    }
  }

  async deleteCompany(id: number) {
    try {
      return await this.http.delete<iCompany>(`${this.API_SERVER}companies`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyCompany;
    }
  }

  async getMattresses() {
    return await this.http.get<iMattress[]>(`${this.API_SERVER}mattresses`).toPromise();
  }

  async createMattress(mattress: iMattress) {
    try {
      return await this.http.post<iMattress>(`${this.API_SERVER}mattresses`, mattress).toPromise();
    } catch (e) {
      return emptyMattress;
    }
  }

  async updateMattress(mattress: iMattress) {
    try {
      return await this.http.put<iMattress>(`${this.API_SERVER}mattresses`, mattress).toPromise();
    } catch (e) {
      return emptyMattress;
    }
  }

  async deleteMattress(id: number) {
    try {
      return await this.http.delete<iMattress>(`${this.API_SERVER}mattresses`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyMattress;
    }
  }

  async getPillows() {
    return await this.http.get<iPillow[]>(`${this.API_SERVER}pillows`).toPromise();
  }

  async createPillow(pillow: iPillow) {
    try {
      return await this.http.post<iPillow>(`${this.API_SERVER}pillows`, pillow).toPromise();
    } catch (e) {
      return emptyPillow;
    }
  }

  async updatePillow(pillow: iPillow) {
    try {
      return await this.http.put<iPillow>(`${this.API_SERVER}pillows`, pillow).toPromise();
    } catch (e) {
      return emptyPillow;
    }
  }

  async deletePillow(id: number) {
    try {
      return await this.http.delete<iPillow>(`${this.API_SERVER}pillows`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyPillow;
    }
  }

  async assignCleanBookingRoom(reservation: number, room: number, revenue?: string) {
    return await this.http.post<any>(`${this.API_SERVER}booking/clean/assign`, { reservation, room, revenue }).toPromise();
  }

  async getCards(guest: iGuest) {
    return await this.http.get<iCard[]>(`${this.API_SERVER}guests/cards`, {
      params: { guest: `${guest.id}` }
    }).toPromise();
  }

  async createCard(guest: iGuest, card: iCard) {
    try {
      return await this.http.post<iCard>(`${this.API_SERVER}guests/cards`, {
        guest: guest.id,
        holder: card.holder,
        number: card.number,
        month: card.month,
        year: card.year,
        cvv: card.cvv
      }).toPromise();
    } catch (e) {
      return emptyPillow;
    }
  }

  async deleteCard(id: number) {
    try {
      return await this.http.delete<iCard>(`${this.API_SERVER}guests/cards`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyCard;
    }
  }

  async processPayment(payment: iPayment, card: iCard, type: string, booking: iBooking) {
    try {
      let request = await this.http.get<any>(`${this.API_SERVER_PUBLIC}redsys/lastbookingreferral`, {
        params: { booking: booking.id }
      }).toPromise();
      if (request.success) {
        return await this.http.post<any>(`${this.API_SERVER_PUBLIC}redsys/payperreferral`, {
          payment: payment.id,
          orderId: request.orderId
        }).toPromise();
      } else {
        return await this.http.post<any>(`${this.API_SERVER_PUBLIC}redsys/${type}`, {
          payment: payment.id,
          card: card.id,
          directPayment: 'MOTO'
        }).toPromise();
      }
    } catch (e) {
      return { error: true };
    }
  }

  async refundPayment(payment: iPayment) {
    return await this.http.post<any>(`${this.API_SERVER_PUBLIC}redsys/refund`, { payment: payment.id }).toPromise();
  }

  /* async getRestaurantOrders (bookingId: string) {    
    return await this.http.get<any>(`${this.API_SERVER_PUBLIC}restaurant/order/reservation/${bookingId}`).toPromise();
  } */

  async cleanUpdate(values: any) {
    return await this.http.post<iRoomNumberClean>(`${this.API_SERVER}booking/clean/room`, values).toPromise();
  }

  async getDailyTasks() {
    return await this.http.get<iTask[]>(`${this.API_SERVER}clean/tasks/daily`).toPromise();
  }

  async getAllDailyTasks() {
    return await this.http.get<iTask[]>(`${this.API_SERVER}clean/tasks/daily/all`).toPromise();
  }

  async createDailyTask(task: iTask) {
    try {
      return await this.http.post<iTask>(`${this.API_SERVER}clean/tasks/daily`, task).toPromise();
    } catch (e) {
      return emptyTask;
    }
  }

  async updateDailyTask(task: iTask) {
    try {
      return await this.http.put<iTask>(`${this.API_SERVER}clean/tasks/daily`, task).toPromise();
    } catch (e) {
      return emptyTask;
    }
  }

  async enableDailyTask(id: number) {
    try {
      return await this.http.put<iTask>(`${this.API_SERVER}clean/tasks/daily/enable`, { id }).toPromise();
    } catch (e) {
      return emptyTask;
    }
  }

  async deleteDailyTask(id: number) {
    try {
      return await this.http.delete<iTask>(`${this.API_SERVER}clean/tasks/daily`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyTask;
    }
  }

  async getMaintenance() {
    return await this.http.get<iMaintenance[]>(`${this.API_SERVER}clean/maintenance`).toPromise();
  }

  async getAllMaintenance() {
    return await this.http.get<iMaintenance[]>(`${this.API_SERVER}clean/maintenance/all`).toPromise();
  }

  async createMaintenance(maintenance: iMaintenance) {
    try {
      return await this.http.post<iMaintenance>(`${this.API_SERVER}clean/maintenance`, maintenance).toPromise();
    } catch (e) {
      return emptyMaintenance;
    }
  }

  async updateMaintenance(maintenance: iMaintenance) {
    try {
      return await this.http.put<iMaintenance>(`${this.API_SERVER}clean/maintenance`, maintenance).toPromise();
    } catch (e) {
      return emptyMaintenance;
    }
  }

  async enableMaintenance(id: number) {
    try {
      return await this.http.put<iMaintenance>(`${this.API_SERVER}clean/maintenance/enable`, { id }).toPromise();
    } catch (e) {
      return emptyMaintenance;
    }
  }

  async deleteMaintenance(id: number) {
    try {
      return await this.http.delete<iMaintenance>(`${this.API_SERVER}clean/maintenance`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyMaintenance;
    }
  }

  async getQuestions() {
    return await this.http.get<iQuestion[]>(`${this.API_SERVER}clean/questions`).toPromise();
  }

  async createQuestion(question: iQuestion) {
    try {
      return await this.http.post<iQuestion>(`${this.API_SERVER}clean/questions`, question).toPromise();
    } catch (e) {
      return emptyQuestion;
    }
  }

  async updateQuestion(question: iQuestion) {
    try {
      return await this.http.put<iQuestion>(`${this.API_SERVER}clean/questions`, question).toPromise();
    } catch (e) {
      return emptyQuestion;
    }
  }

  async deleteQuestion(id: number) {
    try {
      return await this.http.delete<iQuestion>(`${this.API_SERVER}clean/questions`, {
        params: { id: `${id}` }
      }).toPromise();
    } catch (e) {
      return emptyQuestion;
    }
  }

  async getQuestionsValues(bookedroom: string) {
    return await this.http.get<iQuestionsBooking[]>(`${this.API_SERVER}clean/questions/${bookedroom}`).toPromise();
  }

  async getReservationLimits() {
    return await this.http.get<iLimits[]>(`${this.API_SERVER}hotels/limits`).toPromise();
  }

  async createReservationLimit(limits: iLimits) {
    try {
      return await this.http.post<iLimits>(`${this.API_SERVER}hotels/limits`, limits).toPromise();
    } catch (e) {
      return emptyLimits;
    }
  }

  async deleteReservationLimit(limits: iLimits) {
    try {
      return await this.http.delete<iLimits>(`${this.API_SERVER}hotels/limits`, {
        params: { id: `${limits.id}` }
      }).toPromise();
    } catch (e) {
      return emptyQuestion;
    }
  }

  async checkReservationLimit(from: string, to: string, type: string) {
    return await this.http.get<{ available: boolean }>(`${this.API_SERVER}booking/limits`, {
      params: { type, from, to }
    }).toPromise();
  }

  async updateComments(id: number, comments: string) {
    return await this.http.post<iBooking>(`${this.API_SERVER}booking/comments`, {
      id, comments
    }).toPromise();
  }

  async newRoomPrice(billing: number, room_price: number) {
    return await this.http.post<{ status: boolean }>(`${this.API_SERVER}billings/room/price`, {
      billing, room_price
    }).toPromise();
  }

  async updateBookingFood(id: number, food: number) {
    return await this.http.post<iResponse>(`${this.API_SERVER}booking/foods`, { id, food }).toPromise();
  }

  async getTutorials() {
    return await this.http.get<iTutorials[]>(`${this.API_SERVER}tutorials?all=1`).toPromise();
  }

  async createTutorial(tutorial: iTutorials) {
    try {
      return await this.http.post<iTutorials>(`${this.API_SERVER}tutorials`, tutorial).toPromise();
    } catch (e) {
      return emptyTutorials;
    }
  }

  async updateTutorial(tutorial: iTutorials) {
    try {
      return await this.http.put<iTutorials>(`${this.API_SERVER}tutorials`, tutorial).toPromise();
    } catch (e) {
      return emptyTutorials;
    }
  }

  async deleteTutorials(id: number) {
    return await this.http.delete<iResponse>(`${this.API_SERVER}tutorials`, {
      params: { id: `${id}` }
    }).toPromise();
  }

  async deleteRestaurant(id: number) {
    return await this.http.delete<iResponse>(`${this.API_SERVER}restaurants`, {
      params: { id: `${id}` }
    }).toPromise();
  }

  async createRestaurant(restaurant: iRestaurant) {
    try {
      return await this.http.post<iRestaurant>(`${this.API_SERVER}restaurants`, restaurant).toPromise();
    } catch (e) {
      return emptyRestaurant;
    }
  }

  async updateRestaurant(restaurant: iRestaurant) {
    try {
      return await this.http.put<iRestaurant>(`${this.API_SERVER}restaurants`, restaurant).toPromise();
    } catch (e) {
      return emptyRestaurant;
    }
  }

  async addOpeningHours(openingHour: iRestaurantOpeningHours) {
    try {
      return await this.http.post<iRestaurantOpeningHours>(`${this.API_SERVER}restaurant/openinghours`, openingHour).toPromise();
    } catch (e) {
      return emptyRestaurantOpeningHours;
    }
  }

  async deleteOpeningHours(id: number) {
    return await this.http.delete<iResponse>(`${this.API_SERVER}restaurant/openinghours`, {
      params: { id: `${id}` }
    }).toPromise();
  }

  async getRestaurants() {
    return await this.http.get<iRestaurant[]>(`${this.API_SERVER}restaurants`).toPromise();
  }

  async getRestaurantProfiles() {
    return await this.http.get<iRestaurantProfile[]>(`${this.API_SERVER}restaurant/profiles`).toPromise();
  }


  async getRestaurantHotels() {
    return await this.http.get<iRestaurantHotels[]>(`${this.API_SERVER}restaurant/hotels`).toPromise();
  }

  async getRestaurantFamilies(restaurant: string) {
    return await this.http.get<iRestaurantFamily[]>(`${this.API_SERVER}restaurant/families`,  { params: { restaurant: restaurant } }).toPromise();
  }

  async createRestaurantFamily(family: iRestaurantFamily) {
    try {
      return await this.http.post<iRestaurantFamily>(`${this.API_SERVER}restaurant/families`, family).toPromise();
    } catch (e) {
      return emptyRestaurantFamily;
    }
  }

  async updateRestaurantFamily(family: iRestaurantFamily) {
    try {
      return await this.http.put<iRestaurantFamily>(`${this.API_SERVER}restaurant/families`, family).toPromise();
    } catch (e) {
      return emptyRestaurantFamily;
    }
  }

  async deleteRestaurantFamily(id: number) {
    return await this.http.delete<iResponse>(`${this.API_SERVER}restaurant/families`, {
      params: { id: `${id}` }
    }).toPromise();
  }

  async getRestaurantProducts(restaurant: string) {
    return await this.http.get<iRestaurantProduct[]>(`${this.API_SERVER}restaurant/products`,  { params: { restaurant: restaurant } }).toPromise();
  }

  async createRestaurantProduct(product: iRestaurantProduct) {
    try {
      return await this.http.post<iRestaurantProduct>(`${this.API_SERVER}restaurant/products`, product).toPromise();
    } catch (e) {
      return emptyRestaurantProduct;
    }
  }

  async updateRestaurantProduct(product: iRestaurantProduct) {
    try {
      return await this.http.put<iRestaurantProduct>(`${this.API_SERVER}restaurant/products`, product).toPromise();
    } catch (e) {
      return emptyRestaurantProduct;
    }
  }

  async deleteRestaurantProduct(id: number) {
    return await this.http.delete<iResponse>(`${this.API_SERVER}restaurant/products`, {
      params: { id: `${id}` }
    }).toPromise();
  }

  async createRestaurantVariant(product: iRestaurantProductVariant) {
    try {
      return await this.http.post<iRestaurantProductVariant>(`${this.API_SERVER}restaurant/product/variants`, product).toPromise();
    } catch (e) {
      return emptyRestaurantProductVariant;
    }
  }

  async updateRestaurantVariant(product: iRestaurantProductVariant) {
    try {
      return await this.http.put<iRestaurantProductVariant>(`${this.API_SERVER}restaurant/product/variants`, product).toPromise();
    } catch (e) {
      return emptyRestaurantProductVariant;
    }
  }

  async deleteRestaurantVariant(id: number) {
    return await this.http.delete<iResponse>(`${this.API_SERVER}restaurant/product/variants`, {
      params: { id: `${id}` }
    }).toPromise();
  }

  async getPromotions() {
    return await this.http.get<iPromotions[]>(`${this.API_SERVER}promotions?all=1`).toPromise();
  }

  async createPromotion(promotion: iPromotions) {
    try {
      return await this.http.post<iPromotions>(`${this.API_SERVER}promotions`, promotion).toPromise();
    } catch (e) {
      return emptyPromotions;
    }
  }

  async updatePromotion(promotion: iPromotions) {
    try {
      return await this.http.put<iPromotions>(`${this.API_SERVER}promotions`, promotion).toPromise();
    } catch (e) {
      return emptyPromotions;
    }
  }

  async deletePromotion(id: number) {
    return await this.http.delete<iResponse>(`${this.API_SERVER}promotions`, {
      params: { id: `${id}` }
    }).toPromise();
  }

  async getCivitatisDestinations() {
    try {
      return (await this.http.get<iCivitatis>(`${this.API_SERVER}civitatis/destinations/hotel`).toPromise()).data;
    } catch (e) {
      return [emptyCivitatisDestinations];
    }
  }

  async getCalendarValues(filter?: iCalendarFilter) {
    return await this.http.get<iCalendar[]>(`${this.API_SERVER}calendar`, { params: { ...filter } }).toPromise();
  }

  async setCalendarValues(calendarValues: iCalendar[]) {
    return await this.http.post<iResponse>(`${this.API_SERVER}calendar`, { values: calendarValues }).toPromise();
  }

  async searchBookingSynergy(from: string, to: string) {
    try {
      return await this.http.get<any>(`${this.API_SERVER}synergy/sync`, { params: { from, to } }).toPromise();
    } catch (e) {
      return [];
    }
  }

  async syncBookingSynergy(reserves: iBookingSynergy[]) {
    try {
      return await this.http.post<any>(`${this.API_SERVER}synergy/sync`, { reserves }).toPromise();
    } catch (e) {
      return [];
    }
  }

  async getGeneralReport(from: string, to: string) {
    return await this.http.get<any>(`${this.API_SERVER}reports/general`, { params: { from, to } }).toPromise();
  }

  async getGeneralReportDetails(reservations: number[]) {
    return await this.http.post<iBilling[]>(`${this.API_SERVER}reports/general`, { reservations }).toPromise();
  }

  async getBookingList(type: string) {
    return await this.http.get<iBooking[]>(`${this.API_SERVER}booking/filter/type`, { params: { type } }).toPromise();
  }

  async getDroppedBillingList(params: HttpParams | { [param: string]: string | string[] }) {
    return await this.http.get<iBilling[]>(`${this.API_SERVER}reports/billings/droppeds`, { params }).toPromise();
  }

  async getDetailsBillingList(params: HttpParams | { [param: string]: string | string[] }) {
    return await this.http.get<iBilling[]>(`${this.API_SERVER}reports/billings/details`, { params }).toPromise();
  }

}